<template>
  <b-list-group
    v-b-hover="handleHover"
    class="bg-gray-primary rounded-0 collapsed-menu vh-100 pb-5"
    :class="{ 'expanded-menu': isMenuExpanded }"
  >
    <b-list-group-item
      class="rounded-0 border-0 clickable mt-2 px-3"
      @click="goToDashboard"
    >
      <b-row class="no-gutters text-white flex-nowrap align-items-end">
        <b-col class="col-auto">
          <img
            width="28px"
            src="./../assets/icons/Icon-Emotiva-2020.svg"
            fluid
            alt="logo"
          />
        </b-col>
        <b-col v-if="isMenuExpanded" class="ml-3 h5 mb-0">Emotiva</b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item
      v-b-visible="handleExpandItemVisibility"
      class="rounded-0 border-0 clickable d-lg-none"
      @click="isExpandItemActive = !isExpandItemActive"
    >
      <b-icon icon="list" variant="white" font-scale="1.0"></b-icon>
    </b-list-group-item>
    <b-list-group-item
      class="rounded-0 border-0 mt-4 clickable"
      :class="{ 'bg-color-light': isRouteActive('Dashboard') }"
      @click="goToPage('Dashboard')"
    >
      <b-row class="no-gutters text-white flex-nowrap align-items-end">
        <b-col class="col-auto">
          <img
            src="./../assets/icons/users.svg"
            class="menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Dashboard') }"
          />
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Dashboard') }"
        >
          {{ $t("sideMenu.users") }}
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{ 'bg-color-light': isRouteActive('Clients') }"
      @click="goToPage('Clients')"
    >
      <b-row class="no-gutters text-white flex-nowrap align-items-end">
        <b-col class="col-auto">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-person-lines-fill menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Clients') }"
          >
            <path
              fill-rule="evenodd"
              d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <!-- <img
            src="./../assets/icons/users.svg"
            class="menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Clients') }"
          /> -->
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Clients') }"
        >
          {{ $t("sideMenu.clients") }}
        </b-col>
      </b-row>
    </b-list-group-item>

    <b-list-group-item></b-list-group-item>

    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{ 'bg-color-light': isRouteActive('Predict-Clients') }"
      @click="goToPage('Predict-Clients')"
    >
      <b-row class="no-gutters text-white flex-nowrap align-items-end">
        <b-col class="col-auto">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-person-lines-fill menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Predict-Clients') }"
          >
            <path
              fill-rule="evenodd"
              d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Predict-Clients') }"
        >
          {{ $t("sideMenu.predictClients") }}
        </b-col>
      </b-row>
    </b-list-group-item>

    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{ 'bg-color-light': isRouteActive('Services') }"
      @click="goToPage('Services')"
    >
      <b-row class="no-gutters text-white flex-nowrap align-items-end">
        <b-col class="col-auto">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-person-lines-fill menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Services') }"
          >
            <path
              fill-rule="evenodd"
              d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Services') }"
        >
          {{ $t("sideMenu.services") }}
        </b-col>
      </b-row>
    </b-list-group-item>

    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{ 'bg-color-light': isRouteActive('Subscriptions') }"
      @click="goToPage('Subscriptions')"
    >
      <b-row class="no-gutters text-white flex-nowrap align-items-end">
        <b-col class="col-auto">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-person-lines-fill menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Subscriptions') }"
          >
            <path
              fill-rule="evenodd"
              d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Subscriptions') }"
        >
          {{ $t("sideMenu.subscriptions") }}
        </b-col>
      </b-row>
    </b-list-group-item>



    <b-list-group-item
      class="rounded-0 border-0 mt-4 clickable"
      :class="{ 'bg-color-light': isRouteActive('Support') }"
      @click="goToPage('Support')"
    >
      <b-row class="no-gutters text-white flex-nowrap align-items-end">
        <b-col class="col-auto">
          <img
            src="./../assets/icons/MENU_support-faq.svg"
            class="menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Support') }"
          />
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Support') }"
        >
          {{ $t("sideMenu.support") }}
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      isHovered: false,
      isExpandItemActive: false,
      isExpandItemVisible: false
    };
  },
  watch: {
    isExpandItemVisible: {
      handler: function() {
        // Close menu on window resize
        if (this.isExpandItemVisible) this.isExpandItemActive = false;
        else this.isHovered = false;
      },
      immediate: false
    }
  },
  computed: {
    isMenuExpanded() {
      return this.isExpandItemVisible
        ? this.isExpandItemActive
        : this.isHovered;
    }
  },
  methods: {
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    handleExpandItemVisibility(val) {
      this.isExpandItemVisible = val;
    },
    goToDashboard() {
      if (this.$route.name !== "Dashboard" && this.$route.name !== "Login") {
        this.isHovered = false;
        this.isExpandItemActive = false;
        this.$router.push({ name: "Dashboard" });
      }
    },
    goToPage(routeName) {
      if (this.$route.name !== routeName) {
        this.isHovered = false;
        this.isExpandItemActive = false;
        this.$router.push({ name: routeName });
      }
    },
    isRouteActive(routeName) {
      return this.$route.name === routeName;
    }
  }
};
</script>

<style scoped>
.collapsed-menu {
  transition: width 0.5s ease;
  z-index: 3;
  width: 60px;
  overflow-x: hidden;
  overflow-y: auto;
}
.expanded-menu {
  transition: width 0.5s ease;
  width: 240px;
}
.bg-gray-primary {
  background-color: #3f3f3f;
}
.list-group-item {
  background-color: #3f3f3f;
}
.menu__text-item {
  font-size: 14px;
  font-weight: 400;
  margin-left: 30px;
}
.menu__text__active {
  color: #3f3f3f;
  font-weight: 700;
}
.bg-color-light {
  background-color: #f8f9fa;
}
.menu__icon {
  width: 14px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg)
    brightness(102%) contrast(101%);
}
.active__menu-icon {
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
}
</style>
